<template>

  <b-card-code
    title="Course Video"
  >
   <b-overlay
    :show="showOverlay"
    rounded="sm"
  >
    <b-row class="mt-2">
        <b-col md="4" v-for="material in materials" :key="material.id">
            
            <b-embed
                type="iframe"
                aspect="16by9"
                allowfullscreen
                :src="material.attachment_url"
                @onload="load()"
            ></b-embed>
        
            <p>{{material.attachment_name}}</p>
       </b-col>
    </b-row>
  </b-overlay>
   </b-card-code>
</template>
<style scoped>
    .b-icon.bi {
    
        font-size: 70px;
    }
</style>
<script>
import BCardCode from '@core/components/b-card-code'
import {
  BRow, BFormDatepicker, BEmbed, BIcon, BInputGroup, BAlert, BInputGroupAppend, BCol, BAvatar,BCardText, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton,BFormFile,BMedia, BMediaAside, BOverlay, BMediaBody, BLink, BImg
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import CourseService from '@/services/course.service.js';

export default {
  components: {
    BCardCode,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    vSelect,
    BFormFile,
    BCardText,
    BFormDatepicker,
    BOverlay,
    BInputGroup,
    BInputGroupAppend,
    BAlert,
    BIcon,
    BEmbed
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      id: this.$route.params.courseId,
      logoImg: require('@/assets/images/pages/logo-1.png'),
      avatar: '@/assets/images/portrait/small/avatar-s-11.jpg',
      showOverlay: false,
      errors: '',
      materials: {},
      monthId: this.$route.params.monthId,
    }
  },
  watch:{
        '$route'(to) {
            this.id = to.params.courseId;
            this.monthId = to.params.monthId;
        } 
    },
  methods: {
    load()
    {
        console.log('load')
    },
    getMaterial() {  
      this.showOverlay = true;
          CourseService.getLoggedInAttachment('video', this.monthId).then(response => {
            this.showOverlay = false;
            this.materials = response.data.data.attachments
            console.log(response.data.data)
            }).catch(error => {
              this.showOverlay = false;
              this.errors = error.response
            });
    },
  },
  mounted() {
    this.getMaterial();
  }
}
</script>

